import React from "react";
import "./home.scss";
import Hero from "../../section/hero/hero.jsx";
import About from "../../section/about/About.jsx";
import Fas from "../../section/fas/fas.jsx";
import Promo from "../../section/promo/Promo.jsx";
import Produk from "../../section/produk/produk.jsx";
import Lokasi from "../../section/lokasi/lokasi.jsx";
import Areas from "../../section/areas/areas.jsx";
import Footer from "../../section/footer/footer.jsx";
import ScrollToHashElement from "../ScrollToHashElement.js";

const home = () => {
  return (
    <div className="home">
      <ScrollToHashElement />
      <Hero />
      <About />
      <Fas />
      <Promo />
      <Produk />
      <Lokasi />
      <Areas />
      <Footer />
    </div>
  );
};

export default home;
