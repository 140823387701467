import Casajardin1 from "./casajardin (1).webp";
import Casajardin2 from "./casajardin (2).webp";
import Casajardin3 from "./casajardin (3).webp";
import Casajardin4 from "./casajardin (4).webp";
import Casajardin5 from "./casajardin (5).webp";
import Casajardin6 from "./casajardin (6).webp";
import Casajardin7 from "./casajardin (7).webp";
import Casajardin8 from "./casajardin (8).webp";
import Casajardin9 from "./casajardin (9).webp";
import Casajardin10 from "./casajardin (10).webp";

const casajardin = [
  Casajardin1,
  Casajardin2,
  Casajardin3,
  Casajardin4,
  Casajardin5,
  Casajardin6,
  Casajardin7,
  Casajardin8,
  Casajardin9,
  Casajardin10,
];

export default casajardin;
