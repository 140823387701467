import React from "react";
import "./fas.scss";

const fas = () => {
  return (
    <div className="fas">
      <div className="container-fas">
        <div className="header-fas"></div>
      </div>
    </div>
  );
};

export default fas;
