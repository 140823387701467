import React from "react";
import "./hero.scss";

const hero = () => {
  return (
    <div className="hero">
      <div className="container-hero">
        <div className="header-hero">
          <div className="judul-hero">WELCOME TO</div>
          <div className="subjudul-hero">KOTA HARAPAN INDAH</div>
        </div>
      </div>
    </div>
  );
};

export default hero;
