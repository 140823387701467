import Lavesh0 from "./lavesh (1)_1.webp";
import Lavesh1 from "./lavesh (1).webp";
import Lavesh2 from "./lavesh (2).webp";
import Lavesh3 from "./lavesh (3).webp";
import Lavesh4 from "./lavesh (4).webp";
import Lavesh5 from "./lavesh (5).webp";
import Lavesh6 from "./lavesh (6).webp";
import Lavesh7 from "./lavesh (7).webp";
import Lavesh8 from "./lavesh (8).webp";
import Lavesh9 from "./lavesh (9).webp";
import Lavesh10 from "./lavesh (10).webp";

const lavesh = [
  Lavesh0,
  Lavesh1,
  Lavesh2,
  Lavesh3,
  Lavesh4,
  Lavesh5,
  Lavesh6,
  Lavesh7,
  Lavesh8,
  Lavesh9,
  Lavesh10,
];

export default lavesh;
