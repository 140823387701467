import Paris1 from "./paris (1).webp";
import Paris2 from "./paris (2).webp";
import Paris3 from "./paris (3).webp";
import Paris4 from "./paris (4).webp";
import Paris5 from "./paris (5).webp";
import Paris6 from "./paris (6).webp";
import Paris7 from "./paris (7).webp";
import Paris8 from "./paris (8).webp";

const paris = [Paris1, Paris2, Paris3, Paris4, Paris5, Paris6, Paris7, Paris8];

export default paris;
