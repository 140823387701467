import Pianta1 from "./pianta (1).webp";
import Pianta2 from "./pianta (2).webp";
import Pianta3 from "./pianta (3).webp";
import Pianta4 from "./pianta (4).webp";
import Pianta5 from "./pianta (5).webp";
import Pianta6 from "./pianta (6).webp";
import Pianta7 from "./pianta (7).webp";
import Pianta8 from "./pianta (8).webp";
import Pianta9 from "./pianta (9).webp";

const pianta = [
  Pianta1,
  Pianta2,
  Pianta3,
  Pianta4,
  Pianta5,
  Pianta6,
  Pianta7,
  Pianta8,
  Pianta9,
];

export default pianta;
