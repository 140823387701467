import Anagata1 from "./anagata (1).webp";
import Anagata2 from "./anagata (2).webp";
import Anagata3 from "./anagata (3).webp";
import Anagata4 from "./anagata (4).webp";
import Anagata5 from "./anagata (5).webp";
import Anagata6 from "./anagata (6).webp";

const anagata = [Anagata1, Anagata2, Anagata3, Anagata4, Anagata5, Anagata6];

export default anagata;
