import Elora1 from "./elora (1).webp";
import Elora2 from "./elora (2).webp";
import Elora3 from "./elora (3).webp";
import Elora4 from "./elora (4).webp";
import Elora5 from "./elora (5).webp";
import Elora6 from "./elora (6).webp";
import Elora7 from "./elora (7).webp";
import Elora8 from "./elora (8).webp";
import Elora9 from "./elora (9).webp";
import Elora10 from "./elora (10).webp";

const elora = [
  Elora1,
  Elora2,
  Elora3,
  Elora4,
  Elora5,
  Elora6,
  Elora7,
  Elora8,
  Elora9,
  Elora10,
];

export default elora;
