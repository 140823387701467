import React from "react";
import "./areas.scss";
import area from "./areas.json";
import Cardrumah from "../../component/cardrumah/cardrumah";
import pianta from "../../asset/pianta/pianta";
import paris from "../../asset/paris/paris";
import casajardin from "../../asset/casajardin/casajardin";
const imageMap = {
  pianta,
  paris,
  casajardin,
};

const Areas = () => {
  return (
    <div id="areas" className="areas">
      <div className="container-areas">
        <div className="container">
          <div className="header-areas">
            <div className="judul-areas">Residential's in Area</div>
          </div>
        </div>
        <div className="cardhome-container">
          {area.cards.map((card, index) => {
            // Determine the logo dynamically
            return (
              <Cardrumah
                key={index}
                carousel={imageMap[card.imagesKey]}
                title={card.title}
                subtitle={card.subtitle}
                price={card.price}
                subprice={card.subprice}
                details={card.details}
                whatsappLink={card.whatsappLink} // Pass the WhatsApp link
              />
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default Areas;
