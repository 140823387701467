import Nishi1 from "./nishi (1).webp";
import Nishi2 from "./nishi (2).webp";
import Nishi3 from "./nishi (3).webp";
import Nishi4 from "./nishi (4).webp";
import Nishi5 from "./nishi (5).webp";
import Nishi6 from "./nishi (6).webp";
import Nishi7 from "./nishi (7).webp";
import Nishi8 from "./nishi (8).webp";
import Nishi9 from "./nishi (9).webp";

const nishi = [
  Nishi1,
  Nishi2,
  Nishi3,
  Nishi4,
  Nishi5,
  Nishi6,
  Nishi7,
  Nishi8,
  Nishi9,
];

export default nishi;
