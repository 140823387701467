import React from "react";
import "./Promo.scss";
import promo from "../../asset/main/promo (1).webp";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckDouble } from "@fortawesome/free-solid-svg-icons";
const Promo = () => {
  const promoitem = [
    "DP 0%",
    "Free PPN",
    "Free Biaya BPHTB",
    "Free Biaya KPR",
    "Free Internet 12 Bulan",
    "Free Kitchen Set",
    "Discount Up to 7%",
    "Free Canopy",
    "Free Smart Door Lock",
    "Free Smart Home System",
  ];

  return (
    <div id="promo" className="promocontainer">
      <div className="promocenter">
        <div className="promoimg">
          <img className="promo" alt="promoimg" src={promo} />
        </div>
        <div className="promopoint">
          <div className="pointtitle">Promo Terkini :</div>
          <div className="point">
            <ul>
              {promoitem.map((slide, index) => (
                <li>
                  <FontAwesomeIcon color="#086D44" icon={faCheckDouble} />
                  &nbsp;{slide}
                </li>
              ))}
              <li className="disclaimer">*Disclaimer</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Promo;
