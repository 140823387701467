import React from "react";
import "./lokasi.scss";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import lokasiimage from "../../asset/lokasi/lokasi";
import mapsimage from "./maps.jpeg";

const lokasi = () => {
  const settings = {
    dots: true,
    arrows: true,
    infinite: true,
    autoplay: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    lazyload: true,
    responsive: [
      {
        breakpoint: 768,
        lazyload: true, // Adjust breakpoint for mobile devices
        settings: {
          slidesToShow: 1, // Show only 1 slide on mobile (optional)
          swipeToSlide: true,
          lazyload: true,
          arrows: false,
        },
      },
    ],
  };
  return (
    <div className="lokasi">
      <div className="container-lokasi">
        <div id="lokasi" className="header-lokasi">
          LOKASI KOTA HARAPAN INDAH
        </div>
        <div className="container-content">
          <div className="carousellokasi">
            <Slider {...settings}>
              {lokasiimage.map((image, index) => (
                <img
                  className="cluster-image"
                  key={index}
                  src={image}
                  alt={`Launching ${index + 1}`}
                />
              ))}
            </Slider>
          </div>
          <div className="mapslokasi">
            <div className="maps-lokasi">
              <img
                id="lokasi"
                className="img-lokasi-dekstop"
                src={mapsimage}
                alt="maps"
              />
              <img
                id="lokasi"
                className="img-lokasi-mobile"
                src={mapsimage}
                alt="maps"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default lokasi;
