import Tera1 from "./tera (1).webp";
import Tera2 from "./tera (2).webp";
import Tera3 from "./tera (3).webp";
import Tera4 from "./tera (4).webp";
import Tera5 from "./tera (5).webp";
import Tera6 from "./tera (6).webp";
import Tera7 from "./tera (7).webp";
import Tera8 from "./tera (8).webp";

const tera = [Tera1, Tera2, Tera3, Tera4, Tera5, Tera6, Tera7, Tera8];

export default tera;
