import React from "react";
import "./App.scss";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "./pages/home/home.jsx";
import { FloatingWhatsApp } from "react-floating-whatsapp";

const App = () => {
  return (
    <div className="App">
      <Router>
        <Routes>
          <Route path="/#promo" element={<Home />} />
          <Route path="/#areas" element={<Home />} />
          <Route path="/#lokasi" element={<Home />} />
          <Route path="/#rumah" element={<Home />} />
          <Route path="/" element={<Home />} />
        </Routes>
      </Router>
      <a
        target="_blank"
        href="https://api.whatsapp.com/send/?phone=628179807998&text=Halo%20Marketing%20Harapan%20Indah,%20Saya%C2%A0bisa%20minta%20detail%20Katalog,%20Simulasi%20dan%20Promo%20terbaru%20Rumah%20https://marketingharapanindah.id/&type=phone_number&app_absent=0"
      >
        <FloatingWhatsApp />
      </a>
    </div>
  );
};

export default App;
