import Nismara0 from "./nismara (0).webp";
import Nismara1 from "./nismara (1).webp";
import Nismara2 from "./nismara (2).webp";
import Nismara3 from "./nismara (3).webp";
import Nismara4 from "./nismara (4).webp";
import Nismara5 from "./nismara (5).webp";
import Nismara6 from "./nismara (6).webp";
import Nismara7 from "./nismara (7).webp";
import Nismara8 from "./nismara (8).webp";
import Nismara9 from "./nismara (9).webp";
import Nismara10 from "./nismara (10).webp";
import Nismara11 from "./nismara (11).webp";

const nismara = [
  Nismara0,
  Nismara1,
  Nismara2,
  Nismara3,
  Nismara4,
  Nismara5,
  Nismara6,
  Nismara7,
  Nismara8,
  Nismara9,
  Nismara10,
  Nismara11,
];

export default nismara;
