import Lokasi1 from "./lokasi (1).webp";
import Lokasi2 from "./lokasi (2).webp";
import Lokasi3 from "./lokasi (3).webp";
import Lokasi4 from "./lokasi (4).webp";
import Lokasi5 from "./lokasi (5).webp";
import Lokasi6 from "./lokasi (6).webp";
import Lokasi7 from "./lokasi (7).webp";
import Lokasi8 from "./lokasi (8).webp";
import Lokasi9 from "./lokasi (9).webp";
import Lokasi10 from "./lokasi (10).webp";
import Lokasi11 from "./lokasi (11).webp";

const lokasi = [
  Lokasi1,
  Lokasi2,
  Lokasi3,
  Lokasi4,
  Lokasi5,
  Lokasi6,
  Lokasi7,
  Lokasi8,
  Lokasi9,
  Lokasi10,
  Lokasi11,
];

export default lokasi;
