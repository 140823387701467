import React from "react";
import "./footer.scss";
import logo from "../../asset/main/logo (1).webp";

const footer = () => {
  return (
    <div className="container-footer">
      <div className="container-atas">
        <div className="container-logo">
          <img className="gambar-logo" src={logo} alt="logo-bsd" />
        </div>
        <div className="container-deskripsi">
          <div className="alamat">
            <h2>Marketing Gallery</h2>
            <h1>Kota Harapan Indah</h1>
            <p>Harapan Indah, RT.005/RW.010, Medan Satria, Bekasi, West Java</p>
          </div>
          <div className="contact">Contact Marketing : +628179807998 </div>
          <div className="privasi"></div>
        </div>
      </div>
      <div className="container-bawah">KOTA HARAPAN INDAH</div>
    </div>
  );
};

export default footer;
